import chalk from "chalk";

export class CustomError extends Error {
  constructor(message, name = undefined) {
    super(message, name);
    const startSeparator = new Array(message.length - (name?.length + 2 || 10))
      .fill("-")
      .join("");
    const endSeparator = new Array(message.length + 3).fill("-").join("");
    this.name = chalk.black.bgMagenta.bold(`⛔  ${this.name}`);
    this.message = chalk.magenta.bold(
      startSeparator + "\n" + message + "  |" + "\n" + endSeparator,
    );
  }
}
