import { ClubRead } from "@/TS/Interfaces/global_interfaces";
import { CustomError } from "@/utils/init-globals";

export const isPresident = (club: ClubRead): boolean => {
  return club?.userRole === "ROLE_PRESIDENT";
};

export function mergeObject<T>(...objs: Array<any>): T {
  if (objs.length < 2) {
    throw new CustomError(
      "Please enter at least one more object to merge them",
    );
  }
  return objs.reduce((acc, obj) => {
    return { ...acc, ...obj };
  }, {} as T);
}

export function removeEmptyFields(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] == null) {
      delete data[key];
    }
    if (typeof data[key] === "object") {
      removeEmptyFields(data[key]);
    }
  });
}
