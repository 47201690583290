"use client";

import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import UserProfileShow from "@/app/(main)/_user_components/user-profile-show";
import { SidebarContextProps } from "@/TS/Interfaces/global_interfaces";
export const sidebarContext = createContext<SidebarContextProps | null>(null);
function SidebarProvider({
  children
}): ReactNode {
  const [val, setVal] = useState("");
  const [jsxValue, setJsxValue] = useState("");
  const [isHide, setIsHide] = useState(false);
  const [panelRef, setPanelRef] = useState(null);
  const handleJsx = () => {
    setVal(jsxValue);
  };
  useEffect(() => {
    document.body.style.overflow = "visible";
  }, []);
  useEffect(() => {
    if (!val == "") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isHide, val]);
  return <sidebarContext.Provider value={{
    val,
    setVal,
    isHide,
    setIsHide,
    handleJsx,
    setJsxValue,
    panelRef,
    setPanelRef
  }} data-sentry-element="unknown" data-sentry-component="SidebarProvider" data-sentry-source-file="sidebar-context.tsx">
      {children}
    </sidebarContext.Provider>;
}
export default SidebarProvider;