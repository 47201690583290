import React, { RefObject, useContext } from "react";
import Icon from "@/components/icon";
import { sidebarContext } from "@/context/sidebar-context";
import { LeadTransitionEnum } from "@/TS/Enums/global_enums";
import { GetActionsOptionsProps, LeadTransition, TagColors } from "@/TS/Interfaces/global_interfaces";
import { isPresident } from "@/utils/global-utils";
export const GetActionsOptions = ({
  CustomView,
  onClick,
  authorizedTransitionsEnum,
  status,
  slug,
  setLeadsStats,
  myProfile,
  allowedTransitions,
  recipientIsUser,
  senderIsUser,
  club,
  ...rest
}: GetActionsOptionsProps) => {
  const {
    setVal
  } = useContext(sidebarContext);
  const handleClickAction = transition => {
    if (onClick) {
      onClick();
      return;
    }
    setVal(<CustomView status={status} slug={slug} club={club} allowedTransitions={allowedTransitions} preTransition={transition} myProfile={myProfile} {...rest}></CustomView>);
  };
  const getFilteredAllowedTransitions = allowedTransitions.filter(transition => {
    if (!authorizedTransitionsEnum) return true;
    return transition.toUpperCase() in authorizedTransitionsEnum;
  });
  return getFilteredAllowedTransitions.map(transition => {
    return <label key={transition} onClick={() => handleClickAction(transition)} className={"hover:bg-light-grey px-6 py-3 transition-all cursor-pointer has-[:checked]:bg-light-grey"}>
        <input className={"hidden checked:bg-light-grey"} type="radio" name={"sort"} />
        {getTransitionLabel(transition, senderIsUser, recipientIsUser, isPresident(club))}
      </label>;
  });
};
const getFlashLeadMessage = transition => {
  LeadTransitionEnum;
};
export const getColor = (color: TagColors): {
  bg: string;
  text: string;
} => {
  let colors: {
    bg: string;
    text: string;
  };
  switch (color) {
    case "traded":
    case "signed":
    case "sent":
      colors = {
        bg: "#CCECFC",
        text: "#009EF1"
      };
      break;
    case "accepted":
      colors = {
        bg: "#D3C9EB",
        text: "#9A7CDE"
      };
      break;
    case "cancelled":
      colors = {
        bg: "#E6E6E6",
        text: "#9B9B9B"
      };
      break;
    case "created":
    case "president_accepted":
    case "guest_accepted":
    case "present":
    case "transformed":
      colors = {
        bg: "#D0EEE4",
        text: "#14AB76"
      };
      break;
    case "transformedAmount":
      colors = {
        bg: "#CDE9F2",
        text: "#234653"
      };
      break;
    case "wait":
    case "pending_sign":
    case "received":
      colors = {
        bg: "#FCE8CC",
        text: "#F18A00"
      };
      break;
    case "president_refused":
    case "refused":
    case "absent":
    case "not_transformed":
    case "expired":
      colors = {
        bg: "#FCDAD9",
        text: "#FC4E49"
      };
      break;
    default:
      colors = {
        bg: "#CDE9F2",
        text: "#234653"
      };
      break;
  }
  return colors;
};
export const getLabelPastelTag = (statut: string) => {
  switch (statut) {
    case "traded":
      return "Échangé";
    case "president_accepted":
      return "Acceptée";
    case "guest_accepted":
      return "Acceptée";
    case "accepted":
      return "Accepté";
    case "transformed":
      return "Transformé";
    case "cancelled":
      return "Annulé";
    case "not_transformed":
      return "Non transformé";
    case "received":
      return "Reçu";
    case "president_refused":
      return "Refusée";
    case "refused":
      return "Refusé";
    case "sent":
      return "Envoyé";
    case "wait":
      return "En attente";
    case "present":
      return "Présent(e)";
    case "absent":
      return "Absent(e)";
    case "created":
      return "Crée";
    case "signed":
      return "Contrat signé";
    case "pending_sign":
      return "En attente de signature";
    case "expired":
      return "Expiré";
  }
};
export const getTransitionLabel = (transition: string, senderIsUser?: string, recipientIsUser?: string, isPresident?: boolean): string => {
  switch (transition) {
    case "accept":
      return "Accepter";
    case "refuse":
      return "Refuser";
    case "cancel":
      return (senderIsUser || isPresident) && "Annuler le lead";
    case "transform":
      return "Transformer le lead";
    case "not_transform":
      return "Ne pas transformer le lead";
  }
};
export const getTransformedTransition = (transition, options): LeadTransition => {
  switch (transition) {
    case LeadTransitionEnum.ACCEPT:
      return {
        transition: transition
      };
    case LeadTransitionEnum.REFUSE:
      return {
        transition: transition,
        reasonForRefusal: options?.reasonForRefusal
      };
    case LeadTransitionEnum.CANCEL:
      return {
        transition: transition
      };
    case LeadTransitionEnum.NOT_TRANSFORM:
      return {
        transition: transition
      };
  }
};
export const getLeadIcon = colorTheme => {
  switch (colorTheme) {
    case "accept":
      return <Icon icon={"check-simple"}></Icon>;
    case "refuse":
      return <Icon icon={"cross"}></Icon>;
  }
};
function getPositionLeft(obj) {
  var curleft = obj.offsetLeft;
  if (obj.offsetParent) {
    curleft = obj.offsetLeft;
    while (obj = obj.offsetParent) {
      curleft += obj.offsetLeft;
    }
  }
  return curleft;
}
function getPositionTop(obj) {
  var curleft = obj.offsetTop;
  if (obj.offsetParent) {
    curleft = obj.offsetTop;
    while (obj = obj.offsetParent) {
      curleft += obj.offsetTop;
    }
  }
  return curleft;
}
export const calculateCoords = (setter, ref, additionalX, additionalY = 0) => {
  if (ref.current) {
    const coords = {
      offsetTop: getPositionTop(ref.current) + additionalY,
      offsetLeft: additionalX ? getPositionLeft(ref.current) - parseInt(ref.current.offsetParent?.clientWidth) : getPositionLeft(ref.current),
      h: ref.current?.offsetHeight,
      w: ref.current?.offsetWidth
    };
    setter(coords);
  }
};
let executed = false;
export function scrollToTop<TElement extends HTMLElement>(scrollTop: number = 0, ref: Window | RefObject<TElement> = window, inputRef: any = null): void {
  if (executed) return;
  if (inputRef?.current.parentElement) {
    let offsetTop = inputRef.current.offsetTop;
    let parent: HTMLElement = inputRef.current.offsetParent as HTMLElement | null;
    while (parent && parent !== document.body) {
      offsetTop += parent.offsetTop;
      parent = parent.offsetParent as HTMLElement | null;
    }
    if ("current" in ref) {
      ref.current?.scrollTo({
        top: offsetTop + scrollTop - (inputRef.current.offsetHeight + 150),
        behavior: "smooth"
      });
    } else {
      ref.scrollTo({
        top: offsetTop + scrollTop - (inputRef.current.offsetHeight + 150),
        behavior: "smooth"
      });
    }
  } else {
    window.scrollTo({
      top: scrollTop,
      behavior: "smooth"
    });
  }
  executed = true;
  setTimeout(() => {
    executed = false;
  }, 1000);
}
export const getAuthorizedTransitions = (transition, lead): boolean => {
  if (lead.senderIsUser) {
    const allowedList = [LeadTransitionEnum.CANCEL];
    return allowedList.includes(transition);
  } else if (lead.recipientIsUser) {
    const allowedList = [LeadTransitionEnum.ACCEPT, LeadTransitionEnum.MODIFY, LeadTransitionEnum.REFUSE, LeadTransitionEnum.TRANSFORM, LeadTransitionEnum.NOT_TRANSFORM];
    return allowedList.includes(transition);
  }
  return false;
};
export const getNameFromSenderOrRecipient = person => {
  if (person?.firstName || person?.lastName) {
    return person?.firstName + " " + person?.lastName;
  }
  return "-";
};
export const isLeader = (lead, myProfile) => {
  if (!lead) return;
  return lead.sender?.lastName.toLowerCase().includes(myProfile.lastName.toLowerCase()) && lead.sender?.firstName.toLowerCase().includes(myProfile.firstName.toLowerCase()) || lead.recipient?.lastName.toLowerCase().includes(myProfile.lastName.toLowerCase()) && lead.recipient?.firstName.toLowerCase().includes(myProfile.firstName.toLowerCase());
};
function areEqual(userAccepted, searchStatus = null) {
  if (userAccepted.length !== searchStatus?.length) return false;
  return userAccepted.every((value, index) => value === searchStatus[index]);
}